import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';

import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import branchReducer, { BranchStateType } from '../../store/branch/reducer';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import childReducer, { ChildStateType } from '../../store/child/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import commentReducer, { CommentStateType } from '../../store/comment/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  branch: BranchStateType;
  employee: EmployeeStateType;
  child: ChildStateType;
  payment: PaymentStateType;
  comment: CommentStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  branch: branchReducer,
  employee: employeeReducer,
  child: childReducer,
  payment: paymentReducer,
  comment: commentReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
