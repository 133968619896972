export const routes = {
  homepage: '/',
  privacyPolicy: '/privacy-policy',
  login: '/login',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  childRegistration: '/registracija',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  branches: {
    list: '/admin/branches',
    edit: '/admin/branches/:id',
    create: '/admin/branches/new',
  },
  employees: {
    list: '/admin/employees',
    create: '/admin/employees/new',
    edit: '/admin/employees/:id',
  },
  children: {
    list: '/admin/children',
    create: '/admin/children/new',
    edit: '/admin/children/:id',
  },
  translations: '/admin/translations',
  payments: '/admin/payments',
  comingSoon: '/coming-soon',
  languages: '/admin/languages',
};
