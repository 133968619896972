import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import { routes } from '../../config/Router/routes';
import {
  faGlobe,
  faHouseChimney,
  faUserGroup,
  faUsers,
  faChildren,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import { Branch } from '../../domain/Branch';
import * as locationService from '../../store/branch/service';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  branchOptions: Branch[];
  onBranchOptionsFetch: () => void;
  currentUser: User | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
  roles: Roles[];
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
  roles: Roles[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  branchOptions,
  onBranchOptionsFetch,
  currentUser,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();

  const { width } = useWindowSize();

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.ADMIN, Roles.TEACHER],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_BRANCHES'),
          to: routes.branches.list,
          icon: faHouseChimney as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_EMPLOYEES'),
          to: routes.employees.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN, Roles.TEACHER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CHILDREN'),
          to: routes.children.list,
          icon: faChildren as IconProp,
          roles: [Roles.ADMIN, Roles.TEACHER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payments,
          icon: faMoneyBill as IconProp,
          roles: [Roles.ADMIN, Roles.TEACHER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUserGroup as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      onBranchOptionsFetch();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsMobileMenuOpen(false);
      window.scroll(0, 0);
    }, 100);
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [location.key]);

  const getNavigationGroups = () => {
    if (!currentUser) {
      return [];
    }

    return SIDEBAR_ITEMS.filter((item) =>
      item.roles.includes(currentUser.role),
    );
  };

  if (
    location.pathname.includes(routes.login) ||
    location.pathname.includes(routes.childRegistration)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentUser={currentUser}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter(
                  (item) =>
                    currentUser && item.roles.includes(currentUser.role),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  branchOptions: state.branch.branchOptions,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onBranchOptionsFetch: () => dispatch(locationService.fetchBranchOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
