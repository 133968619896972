import * as actionTypes from './actionTypes';
import { BranchActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Branch } from '../../domain/Branch';
import { ListResults } from '../../common/List/List';

export type BranchStateType = {
  branchesList: ListResults<Branch> | null;
  branchesLoading: boolean;
  branchesError: HttpError;
  branchesListUpdateNeeded: boolean;
  branchUpdateLoading: boolean;
  branchUpdateError: HttpError;
  branchUpdateSuccess: boolean;
  branch: Branch | null;
  branchLoading: boolean;
  branchError: HttpError;
  branchOptions: Branch[];
  branchOptionsLoading: boolean;
  branchOptionsError: HttpError;
  allBranches: Branch[] | null;
  allBranchesLoading: boolean;
  allBranchesError: HttpError;
  branchCreateError: HttpError;
  branchCreateLoading: boolean;
  branchCreateSuccess: boolean;
  branchDeleteError: HttpError;
  branchDeleteLoading: boolean;
};

export type BranchActionType = BranchStateType & {
  type: BranchActionTypes;
};

export const initialState: BranchStateType = {
  branchesList: null,
  branchesLoading: true,
  branchesError: null,
  branchesListUpdateNeeded: false,
  branchUpdateLoading: false,
  branchUpdateError: null,
  branchUpdateSuccess: false,
  branch: null,
  branchLoading: false,
  branchError: null,
  branchOptions: [],
  branchOptionsLoading: true,
  branchOptionsError: null,
  allBranchesError: null,
  allBranches: null,
  allBranchesLoading: false,
  branchCreateError: null,
  branchCreateLoading: false,
  branchCreateSuccess: false,
  branchDeleteLoading: false,
  branchDeleteError: null,
};

const fetchBranchesStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchesLoading: true,
  branchCreateSuccess: false,
});

const fetchBranchesSuccess = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchesList: action.branchesList,
  branchesLoading: false,
  branchesError: null,
  branchesListUpdateNeeded: false,
});

const fetchBranchesFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchesError: action.branchesError,
  branchesLoading: false,
});

const fetchBranchStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchLoading: true,
});

const fetchBranchSuccess = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branch: action.branch,
  branchLoading: false,
  branchError: null,
});

const fetchBranchFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchError: action.branchError,
  branchLoading: false,
});

const fetchBranchOptionsStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchOptionsLoading: true,
});

const fetchBranchOptionsSuccess = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchOptions: action.branchOptions,
  branchOptionsLoading: false,
  branchOptionsError: null,
});

const fetchBranchOptionsFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchOptionsError: action.branchOptionsError,
  branchOptionsLoading: false,
});

const fetchAllBranchesStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  allBranchesLoading: true,
});

const fetchAllBranchesSuccess = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  allBranches: action.allBranches,
  allBranchesLoading: false,
  allBranchesError: null,
});

const fetchAllBranchesFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  allBranchesError: action.allBranchesError,
  allBranchesLoading: false,
});

const createBranchStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchCreateLoading: true,
  branchCreateSuccess: false,
});

const createBranchSuccess = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchCreateLoading: false,
  branchCreateError: null,
  branchCreateSuccess: true,
});

const createBranchFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchCreateLoading: false,
  branchCreateError: action.branchCreateError,
});

const updateBranchStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchUpdateLoading: true,
  branchUpdateSuccess: false,
});

const updateBranchSuccess = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchUpdateLoading: false,
  branchUpdateError: null,
  branchUpdateSuccess: true,
});

const updateBranchFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchUpdateLoading: false,
  branchUpdateError: action.branchUpdateError,
});

const deleteBranchStart = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchDeleteLoading: true,
});

const deleteBranchSuccess = (state: BranchStateType): BranchStateType => ({
  ...state,
  branchDeleteLoading: false,
  branchDeleteError: null,
  branchesListUpdateNeeded: true,
});

const deleteBranchFail = (
  state: BranchStateType,
  action: BranchActionType,
): BranchStateType => ({
  ...state,
  branchDeleteLoading: false,
  branchDeleteError: action.branchDeleteError,
});

const resetBranchStore = (state: BranchStateType): BranchStateType => ({
  ...initialState,
  branchOptions: state.branchOptions,
});

const completelyResetBranchStore = (): BranchStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BranchActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BRANCHES_START:
      return fetchBranchesStart(state);
    case actionTypes.FETCH_BRANCHES_SUCCESS:
      return fetchBranchesSuccess(state, action);
    case actionTypes.FETCH_BRANCHES_FAIL:
      return fetchBranchesFail(state, action);
    case actionTypes.FETCH_BRANCH_START:
      return fetchBranchStart(state);
    case actionTypes.FETCH_BRANCH_SUCCESS:
      return fetchBranchSuccess(state, action);
    case actionTypes.FETCH_BRANCH_FAIL:
      return fetchBranchFail(state, action);
    case actionTypes.FETCH_BRANCH_OPTIONS_START:
      return fetchBranchOptionsStart(state);
    case actionTypes.FETCH_BRANCH_OPTIONS_SUCCESS:
      return fetchBranchOptionsSuccess(state, action);
    case actionTypes.FETCH_BRANCH_OPTIONS_FAIL:
      return fetchBranchOptionsFail(state, action);
    case actionTypes.FETCH_BRANCH_ALL_START:
      return fetchAllBranchesStart(state);
    case actionTypes.FETCH_BRANCH_ALL_SUCCESS:
      return fetchAllBranchesSuccess(state, action);
    case actionTypes.FETCH_BRANCH_ALL_FAIL:
      return fetchAllBranchesFail(state, action);
    case actionTypes.CREATE_BRANCH_START:
      return createBranchStart(state);
    case actionTypes.CREATE_BRANCH_SUCCESS:
      return createBranchSuccess(state);
    case actionTypes.CREATE_BRANCH_FAIL:
      return createBranchFail(state, action);
    case actionTypes.UPDATE_BRANCH_START:
      return updateBranchStart(state);
    case actionTypes.UPDATE_BRANCH_SUCCESS:
      return updateBranchSuccess(state);
    case actionTypes.UPDATE_BRANCH_FAIL:
      return updateBranchFail(state, action);
    case actionTypes.DELETE_BRANCH_START:
      return deleteBranchStart(state);
    case actionTypes.DELETE_BRANCH_SUCCESS:
      return deleteBranchSuccess(state);
    case actionTypes.DELETE_BRANCH_FAIL:
      return deleteBranchFail(state, action);
    case actionTypes.RESET_BRANCH_STORE:
      return resetBranchStore(state);
    case actionTypes.LOGOUT:
      return completelyResetBranchStore();
    default:
      return state;
  }
};

export default reducer;
