import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Payment } from '../../domain/Payment';
import { ListResults } from '../../common/List/List';
import { PaymentActionTypes } from './actionTypes';

export type PaymentStateType = {
  paymentsList: ListResults<Payment> | null;
  paymentsLoading: boolean;
  paymentsError: HttpError;
  paymentsUpdateNeeded: boolean;
  childPaymentsList: ListResults<Payment> | null;
  childPaymentsLoading: boolean;
  childPaymentsError: HttpError;
  updatePaymentError: HttpError;
  updatePaymentLoading: boolean;
  markAsPaidError: HttpError;
  markAsPaidLoading: boolean;
  deletePaymentError: HttpError;
  deletePaymentLoading: boolean;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentsList: null,
  paymentsError: null,
  paymentsLoading: false,
  childPaymentsError: null,
  childPaymentsLoading: false,
  childPaymentsList: null,
  paymentsUpdateNeeded: false,
  markAsPaidError: null,
  markAsPaidLoading: false,
  updatePaymentError: null,
  updatePaymentLoading: false,
  deletePaymentError: null,
  deletePaymentLoading: false,
};

const fetchPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsLoading: true,
  paymentsUpdateNeeded: false,
});

const fetchPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsLoading: false,
  paymentsError: null,
  paymentsList: action.paymentsList,
});

const fetchPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsLoading: false,
  paymentsError: action.paymentsError,
});

const fetchChildPaymentsStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  childPaymentsLoading: true,
  paymentsUpdateNeeded: false,
});

const fetchChildPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  childPaymentsLoading: false,
  childPaymentsError: null,
  childPaymentsList: action.childPaymentsList,
});

const fetchChildPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  childPaymentsLoading: false,
  childPaymentsError: action.childPaymentsError,
});

const updatePaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  updatePaymentLoading: true,
});

const updatePaymentSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  updatePaymentLoading: false,
  updatePaymentError: null,
  paymentsUpdateNeeded: true,
});

const updatePaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  updatePaymentLoading: false,
  updatePaymentError: action.updatePaymentError,
});

const deletePaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  deletePaymentLoading: true,
});

const deletePaymentSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  deletePaymentLoading: false,
  deletePaymentError: null,
  paymentsUpdateNeeded: true,
});

const deletePaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  deletePaymentLoading: false,
  deletePaymentError: action.deletePaymentError,
});

const markAsPaidStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  markAsPaidLoading: true,
});

const markAsPaidSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  markAsPaidLoading: false,
  markAsPaidError: null,
  paymentsUpdateNeeded: true,
});

const markAsPaidFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  markAsPaidLoading: false,
  markAsPaidError: action.markAsPaidError,
});

const updateChildPaymentList = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsUpdateNeeded: true,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const completelyResetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS_START:
      return fetchPaymentsStart(state);
    case actionTypes.FETCH_PAYMENTS_SUCCESS:
      return fetchPaymentsSuccess(state, action);
    case actionTypes.FETCH_PAYMENTS_FAIL:
      return fetchPaymentsFail(state, action);
    case actionTypes.FETCH_CHILD_PAYMENTS_START:
      return fetchChildPaymentsStart(state);
    case actionTypes.FETCH_CHILD_PAYMENTS_SUCCESS:
      return fetchChildPaymentsSuccess(state, action);
    case actionTypes.FETCH_CHILD_PAYMENTS_FAIL:
      return fetchChildPaymentsFail(state, action);
    case actionTypes.UPDATE_PAYMENT_START:
      return updatePaymentStart(state);
    case actionTypes.UPDATE_PAYMENT_SUCCESS:
      return updatePaymentSuccess(state);
    case actionTypes.UPDATE_PAYMENT_FAIL:
      return updatePaymentFail(state, action);
    case actionTypes.MARK_PAYMENT_AS_PAID_START:
      return markAsPaidStart(state);
    case actionTypes.MARK_PAYMENT_AS_PAID_SUCCESS:
      return markAsPaidSuccess(state);
    case actionTypes.MARK_PAYMENT_AS_PAID_FAIL:
      return markAsPaidFail(state, action);
    case actionTypes.DELETE_PAYMENT_START:
      return deletePaymentStart(state);
    case actionTypes.DELETE_PAYMENT_SUCCESS:
      return deletePaymentSuccess(state);
    case actionTypes.DELETE_PAYMENT_FAIL:
      return deletePaymentFail(state, action);
    case actionTypes.UPDATE_CHILD_PAYMENT_LIST:
      return updateChildPaymentList(state);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return completelyResetPaymentStore();
    default:
      return state;
  }
};

export default reducer;
