import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const ChildRegistrationPage = React.lazy(
  () =>
    import('../../pages/Public/ChildRegistrationPage/ChildRegistrationPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const BranchListPage = React.lazy(
  () => import('../../pages/Admin/Branch/BranchListPage/BranchListPage'),
);

const BranchEditPage = React.lazy(
  () => import('../../pages/Admin/Branch/BranchEditPage/BranchEditPage'),
);

const BranchCreatePage = React.lazy(
  () => import('../../pages/Admin/Branch/BranchCreatePage/BranchCreatePage'),
);

const EmployeeListPage = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeListPage/EmployeeListPage'),
);

const EmployeeCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/Employee/EmployeeCreatePage/EmployeeCreatePage'),
);

const EmployeeEditPage = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeEditPage/EmployeeEditPage'),
);

const ChildListPage = React.lazy(
  () => import('../../pages/Admin/Child/ChildListPage/ChildListPage'),
);

const ChildCreatePage = React.lazy(
  () => import('../../pages/Admin/Child/ChildCreatePage/ChildCreatePage'),
);

const ChildEditPage = React.lazy(
  () => import('../../pages/Admin/Child/ChildEditPage/ChildEditPage'),
);

const PaymentListPage = React.lazy(
  () => import('../../pages/Admin/Payment/PaymentListPage/PaymentListPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  onLanguagesInit: () => void;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  onLanguagesInit,
}: Props) => {
  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(DEFAULT_LOCALE);
  }, []);

  const mappedTranslations = language?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<LoginPage />} />
          <Route
            path={routes.childRegistration}
            element={<ChildRegistrationPage />}
          />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.ADMIN) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.branches.list} />}
          />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.branches.edit} element={<BranchEditPage />} />
          <Route path={routes.branches.create} element={<BranchCreatePage />} />
          <Route path={routes.branches.list} element={<BranchListPage />} />
          <Route
            path={routes.employees.create}
            element={<EmployeeCreatePage />}
          />
          <Route path={routes.employees.edit} element={<EmployeeEditPage />} />
          <Route path={routes.employees.list} element={<EmployeeListPage />} />
          <Route path={routes.children.create} element={<ChildCreatePage />} />
          <Route path={routes.children.edit} element={<ChildEditPage />} />
          <Route path={routes.children.list} element={<ChildListPage />} />
          <Route path={routes.payments} element={<PaymentListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.languages} element={<LanguagesListPage />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.TEACHER) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.children.list} />}
          />
          <Route path={routes.employees.list} element={<EmployeeListPage />} />
          <Route path={routes.children.create} element={<ChildCreatePage />} />
          <Route path={routes.children.edit} element={<ChildEditPage />} />
          <Route path={routes.children.list} element={<ChildListPage />} />
          <Route path={routes.payments} element={<PaymentListPage />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    return <></>;
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
