export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL';

export const FETCH_CHILD_PAYMENTS_START = 'FETCH_CHILD_PAYMENTS_START';
export const FETCH_CHILD_PAYMENTS_SUCCESS = 'FETCH_CHILD_PAYMENTS_SUCCESS';
export const FETCH_CHILD_PAYMENTS_FAIL = 'FETCH_CHILD_PAYMENTS_SUCCESS';

export const UPDATE_PAYMENT_START = 'UPDATE_PAYMENT_START';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAIL = 'UPDATE_PAYMENT_FAIL';

export const MARK_PAYMENT_AS_PAID_START = 'MARK_PAYMENT_AS_PAID_START';
export const MARK_PAYMENT_AS_PAID_SUCCESS = 'MARK_PAYMENT_AS_PAID_SUCCESS';
export const MARK_PAYMENT_AS_PAID_FAIL = 'MARK_PAYMENT_AS_PAID_FAIL';

export const DELETE_PAYMENT_START = 'DELETE_PAYMENT_START';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAIL = 'DELETE_PAYMENT_FAIL';

export const UPDATE_CHILD_PAYMENT_LIST = 'UPDATE_CHILD_PAYMENT_LIST';

export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENTS_START
  | typeof FETCH_PAYMENTS_SUCCESS
  | typeof FETCH_PAYMENTS_FAIL
  | typeof FETCH_CHILD_PAYMENTS_START
  | typeof FETCH_CHILD_PAYMENTS_SUCCESS
  | typeof FETCH_CHILD_PAYMENTS_FAIL
  | typeof UPDATE_PAYMENT_START
  | typeof UPDATE_PAYMENT_SUCCESS
  | typeof UPDATE_PAYMENT_FAIL
  | typeof MARK_PAYMENT_AS_PAID_START
  | typeof MARK_PAYMENT_AS_PAID_SUCCESS
  | typeof MARK_PAYMENT_AS_PAID_FAIL
  | typeof DELETE_PAYMENT_START
  | typeof DELETE_PAYMENT_SUCCESS
  | typeof DELETE_PAYMENT_FAIL
  | typeof UPDATE_CHILD_PAYMENT_LIST
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
